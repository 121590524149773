import ReactGA from 'react-ga4';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';

import Layout from '@/layouts';

import '@/styles/globals.css';

const ZHead = () => {
  return (
    <Head>
      <title>Zilong Website</title>
      <meta name='description' content='Zilong website' />
      <link rel='icon' href='/favicon.ico' />
    </Head>
  );
};

export default function App({ Component, pageProps }: AppProps) {
  const router = useRouter();

  ReactGA.initialize(process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID!);

  if (router.pathname === '/resume') {
    return (
      <div className='min-h-screen'>
        <ZHead />
        <Component {...pageProps} />
      </div>
    );
  }

  return (
    <div className='min-h-screen'>
      <ZHead />
      <Layout>
        <Component {...pageProps} />
      </Layout>
    </div>
  );
}
