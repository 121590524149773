import { ReactNode } from 'react';

import FacebookSVG from '@/assets/icons/contact/facebook.svg';
import GithubSVG from '@/assets/icons/contact/github.svg';
import LinkedInSVG from '@/assets/icons/contact/linkedin.svg';

export interface IContactItem {
  icon: ReactNode;
  label: string;
  href: string;
}

export const contactItems: IContactItem[] = [
  {
    icon: <LinkedInSVG />,
    label: 'LinkedIn',
    href: 'https://www.linkedin.com/in/zilongtw/',
  },
  {
    icon: <GithubSVG />,
    label: 'Github',
    href: 'https://github.com/ZilongRRR',
  },
  {
    icon: <FacebookSVG />,
    label: 'Facebook',
    href: 'https://www.facebook.com/ZilongMeeee/',
  },
];
