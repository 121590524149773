import React from 'react';
import { motion, useCycle } from 'framer-motion';

import ContactList from './ContactList';
import { ContactToggle } from './ContactToggle';

const ContactGroup = () => {
  const [isOpen, toggleOpen] = useCycle(false, true);

  return (
    <div className='absolute bottom-10 left-0'>
      <div className='root'>
        <div className='container'>
          <motion.nav
            initial={false}
            animate={isOpen ? 'open' : 'closed'}
            className='hidden flex-col gap-4 md:flex'
          >
            <ContactList isOpen={isOpen} />
            <ContactToggle toggle={toggleOpen} />
          </motion.nav>
        </div>
      </div>
    </div>
  );
};

export default ContactGroup;
