import React from 'react';

import CodeSVG from '@/assets/icons/header/code.svg';

import { INavItem, NavItemList } from './data';

const NavItem = ({ item }: { item: INavItem }) => {
  return (
    // nav to item.href

    <a href={item.href} className='text-body-20-bold' key={item.label}>
      {item.label}
    </a>

    // <div key={item.label} className='text-body-20-bold'>
    //   {item.label}
    // </div>
  );
};

const Header = () => {
  return (
    <nav className='root'>
      <div className='container mt-7 flex flex-row items-center justify-between px-4'>
        <div className='flex flex-row items-center gap-2'>
          <CodeSVG />
          <div className='text-title-30-bold'>Zilong Zhao</div>
        </div>
        <div className='hidden flex-row gap-10 md:flex'>
          {NavItemList.map((item, index) => (
            <NavItem item={item} key={item.label} />
          ))}
        </div>
      </div>
    </nav>
  );
};

export default Header;
