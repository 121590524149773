export interface INavItem {
  label: string;
  href: string;
}

export const NavItemList: INavItem[] = [
  {
    label: 'Home',
    href: '/',
  },
  // {
  //   label: 'Project',
  //   href: '/project',
  // },
  {
    label: 'About',
    href: '/resume',
  },
];
