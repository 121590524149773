import React, { PropsWithChildren } from 'react';

import ContactGroup from '@/components/ContactGroup';
import Header from '@/components/Header';

export interface ILayoutProps {}

const Layout = ({ children }: PropsWithChildren<ILayoutProps>) => {
  return (
    <div className='relative flex min-h-screen flex-col'>
      <Header />
      <main className='relative flex flex-1 flex-grow'>{children}</main>
      <ContactGroup />
    </div>
  );
};

export default Layout;
