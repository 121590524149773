import React from 'react';
import clsx from 'clsx';
import { motion } from 'framer-motion';

import { contactItems, IContactItem } from './data';

const itemVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 10,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const ContactItem = ({
  item,
  isOpen,
}: {
  item: IContactItem;
  isOpen: boolean;
}) => {
  return (
    <motion.li
      className=''
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
      variants={itemVariants}
    >
      <a
        href={item.href}
        target='_blank'
        rel='noreferrer'
        className={clsx(
          'text-body-20-bold',
          isOpen ? 'pointer-event-auto' : 'pointer-events-none'
        )}
      >
        {item.icon}
      </a>
    </motion.li>
  );
};

const listVariants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.05 },
  },
  closed: {
    transition: { staggerChildren: 0.03, staggerDirection: -1 },
  },
};

const ContactList = ({ isOpen }: { isOpen: boolean }) => {
  return (
    <motion.ul
      className='flex w-9 flex-col-reverse gap-4'
      variants={listVariants}
    >
      {contactItems.map((item, index) => (
        <ContactItem item={item} key={item.label} isOpen={isOpen} />
      ))}
    </motion.ul>
  );
};

export default ContactList;
