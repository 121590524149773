import * as React from 'react';
import { motion } from 'framer-motion';

const Path = (props: any) => (
  <motion.path
    fill='transparent'
    strokeWidth='3'
    stroke='currentColor'
    strokeLinecap='round'
    {...props}
  />
);

interface IProps {
  toggle: () => void;
}

export const ContactToggle = ({ toggle }: IProps) => (
  <button onClick={toggle} className='flex  w-9 items-center justify-center'>
    <svg width='34' height='34' viewBox='0 0 34 34' fill='none'>
      <path
        d='M16.8883 0C7.57619 0 0 7.58056 0 16.8983C0 26.2164 7.57619 33.7967 16.8883 33.7967C26.2007 33.7967 33.7772 26.2164 33.7772 16.8983C33.7769 7.58056 26.2005 0 16.8883 0ZM16.8883 32.13C8.49455 32.13 1.66571 25.2972 1.66571 16.8983C1.66571 8.49972 8.49455 1.66667 16.8883 1.66667C25.2821 1.66667 32.1115 8.49972 32.1115 16.8983C32.1112 25.2972 25.2821 32.13 16.8883 32.13Z'
        fill='currentColor'
      />
      <Path
        variants={{
          closed: { d: 'M 10 11 L 24 11' },
          open: { d: 'M 12 22 L 22 12' },
        }}
      />
      <Path
        d='M 10 17 L 24 17'
        variants={{
          closed: { opacity: 1 },
          open: { opacity: 0 },
        }}
        transition={{ duration: 0.1 }}
      />
      <Path
        variants={{
          closed: { d: 'M 10 23 L 24 23' },
          open: { d: 'M 12 12 L 22 22' },
        }}
      />
      {/* <Path
        variants={{
          closed: { d: 'M 2 2.5 L 20 2.5' },
          open: { d: 'M 3 16.5 L 17 2.5' },
        }}
      />
      <Path
        d='M 2 9.423 L 20 9.423'
        variants={{
          closed: { opacity: 1 },
          open: { opacity: 0 },
        }}
        transition={{ duration: 0.1 }}
      />
      <Path
        variants={{
          closed: { d: 'M 2 16.346 L 20 16.346' },
          open: { d: 'M 3 2.5 L 17 16.346' },
        }}
      /> */}
    </svg>
  </button>
);
